<template>
  <div id="app">
<!-- Loading -->

<div class = "loading" v-if="loading==true">
<div class="jumbotron-loading">
<div class="container" style="display: flex; justify-content: center; align-items: center; height: 100vh">

<b-spinner variant="primary" type="grow" ></b-spinner>
<b-spinner variant="primary" type="grow" ></b-spinner>
<b-spinner variant="primary" type="grow" ></b-spinner>

</div>
</div>
</div>

<!-- Loading -->

<b-navbar type="dark" variant="dark">
<b-navbar-nav>
<router-link to="/" style="text-decoration: none; color: inherit;">   
  <b-nav-item>Leads</b-nav-item>
 </router-link>
</b-navbar-nav>

<b-navbar-nav class="ml-auto" v-if="verifySessionStatus() == true">
  <b-navbar-nav class="navControl">

<b-nav-item-dropdown right >
        <!-- Using 'button-content' slot -->
          <template v-slot:button-content> 
            <b-button variant="primary"> 
              <!-- <span class="fa fa-user"></span> User -->
                  <b-icon icon="person-fill" style="color: white;"></b-icon>
                  User
              </b-button>
          </template>


          <!-- <router-link to="/Profile">  <b-dropdown-item href="href">Profile</b-dropdown-item> </router-link> -->
          <b-dropdown-item @click="logout()"> Sign Out</b-dropdown-item>     

        </b-nav-item-dropdown>

        </b-navbar-nav>
</b-navbar-nav>
        
</b-navbar>


<b-alert
:show="dismissCountDown"
dismissible
:variant= "alertVariant"
@dismissed="dismissCountDown=0"
@dismiss-count-down="countDownChanged"
>
{{alertMessage}}
</b-alert>

<div id="chat" >

</div>
     <router-view/>

</div>
</template>


<script>
import {verifySession} from '@/controller/repository.js'
import {logoutUser} from './controller/repository.js'


export default {
  name: 'App',
  components: {
  },data(){

return{


 //Alert messages

   dismissSecs: 5,
   dismissCountDown: 0,
   alertMessage: null,
   alertVariant: null,

   loading: false,

}

},methods:{

  openLoading(){
    this.loading = true
  },closeLoading(){
    this.loading = false
  }, 
                
   countDownChanged(dismissCountDown) {
    this.dismissCountDown = dismissCountDown
  },
  showAlert() {
    this.dismissCountDown = this.dismissSecs
  },
  logout(){

    this.openLoading()
      logoutUser().then(() => {

        this.closeLoading()
        // this.alertMessage = "Logged out successfully",
        // this.alertVariant = "success",
      }).catch((err) =>{
        console.log(err)
        this.closeLoading()
      })

    },

  verifySessionStatus(){
      if(verifySession() == true){
        // this.getBalance()
        // this.sessionStatus = true
        return verifySession()
      }else if(verifySession() == false){
          return false;
       }
        },
        
}
}
</script>

<style>
</style>
