<template>
    <div>
    
      Fundings <br >
    
    
       <b-form-file
          v-model="file1"
          :state="Boolean(file1)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <div class="mt-3">Selected file: {{ file1 ? file1.name : '' }}</div>
    
        <b-button class="mt-3" variant="outline-primary" block @click="handleFile()">ADD</b-button>
    </div>
    </template>
    
    <script>
    import * as XLSX from 'xlsx/xlsx.mjs';
    import {addFundingsByName} from '@/controller/repository.js';
    
    
    export default {
        data() {
    
          return {
                    file1: null,
            }
            
        },methods:{
          handleFile() {
        // const fileInput = document.getElementById('fileInput');
        // const file = fileInput.files[0];
        // console.log("trying to decode")
        // console.log(this.file1)
    
      const reader = new FileReader();
      reader.onload = function(e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet);
        // Do something with the rows
        // console.log(rows);
        
        for(let i = 0;i<rows.length;i++){

          setTimeout(() => {

          let dateString = rows[i]["Funding date (d-m-y)"]
          const thisDate = new Date(dateString.split("-").reverse().join("-"));
          // console.log("date " + thisDate)

          let investorsArray = []
          if(rows[i]["Investor(s)"]) investorsArray = rows[i]["Investor(s)"].split(',')
          // console.log(investorsArray)
          
          let funding = {
            date: thisDate,
            amount: rows[i]["Amount"],
            currency: rows[i]["Currency"],
            type: rows[i]["Type"],
            investors: investorsArray
          }

          let data = {
             name: rows[i]["Company Name"],
             funding: funding
          }

          console.log(JSON.stringify(data))
    
          if(data.name.length > 0 && data.funding != null){
            addFundingsByName(data).then(() => {
              console.log("The funding to " + data.name + " was created successfully")
            }).catch((err) => {  
              console.log("An error occurred while adding funding from " + data.name + " in line " + (i + 1) + err)
            })
    
          }else{
            this.$parent.alertMessage = "Please ensure that all required fields are filled out before submitting."
            this.$parent.alertVariant = "danger"
            this.$parent.showAlert()
          }

          }, 2000 * i);

        }
        
    
      };
      reader.readAsArrayBuffer(this.file1);
    
      }
        }
    }
    
    </script>
    