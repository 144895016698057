<template>
    <div>
    
        <h5 class="mt-4"><center>Edit person </center></h5>
          <br />
    
      <div class= "ml-3" >
    
        <b-form >
    
    <b-container fluid>
    
     <b-row class="justify-content-md-center">
        <b-col sm="2">
          <label for="input-personname">Person name:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-personname" v-model= "name" size="sm" placeholder="Enter person name"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-personcompany">Person company:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-personcompany" v-model= "company" size="sm" placeholder="Enter person company (be careful)"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-title">Title:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-title" v-model= "title" size="sm" placeholder="Enter titles separated by commas"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-personemail">Person email:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-personemail" v-model= "email" size="sm" placeholder="Enter person email"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-phones">Phone numbers:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-phones" v-model= "phones" size="sm" placeholder="Enter phone numbers separated by commas (use + country code) (optional)"></b-form-input>
        </b-col>
      </b-row>


      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-gender">Gender:</label>
        </b-col>
        <b-col sm="6">
            <b-form-select id="gender" v-model="gender" :options="genderOptions" size="sm"></b-form-select>
        </b-col>
      </b-row>
    

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-tags">Tags:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-tags" v-model= "tags" size="sm" placeholder="Enter tags separated by commas (optional)"></b-form-input>
        </b-col>
      </b-row>

    
    <div v-for="socialField in socialQuantity" v-bind:key = "'socialField' + socialField" >
         <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-socialprinting">Social [{{socialField}}]: </label>
        </b-col>
        <b-col sm="6">
    
      <b-input-group>
        <b-form-input placeholder="Social media name (optional)" size="sm" v-model="socialName[socialField]"></b-form-input>
        <b-form-input placeholder="Link (optional) (just add www. and not 'https://')" size="sm" v-model="socialLink[socialField]"></b-form-input>
    
        <div v-if="socialField==socialQuantity">
            <b-button size="sm" class="ml-2" @click="increaseSocialQuantity()">
                    <b-icon icon="plus" ></b-icon>
            </b-button>
    
               <b-button size="sm" class="ml-1" @click="decreaseSocialQuantity()">
                    <b-icon icon="dash" ></b-icon>
            </b-button>
        </div>
      </b-input-group>
    
          </b-col>
      </b-row>
    </div>



    
    </b-container>
    
         <center class="mt-2 mb-2">
            <router-link to="/People">  <b-button variant="success" >Back</b-button> </router-link>
          <b-button variant="primary"  class="ml-2" @click="addPerson()">Submit</b-button>
          <b-button type="reset" class="ml-2" variant="danger" @click="onReset">Reset</b-button>  
        </center>
    
    
        </b-form>
    
      </div>
      </div>
    </template>
    
    <script>
    import {getPerson} from '@/controller/repository.js'
    import {editPerson} from '@/controller/repository';    
            
    export default {
    
        data(){
            return{

                genderOptions: [
                { value: null, text: 'Please select a gender (optional)' },
                { value: "Male", text: 'Male' },
                { value: 'Female', text: 'Female' },
                ],
                gender: null,

                socialQuantity: 1,    
                socialName: [],
                socialLink: [],
                name:"",
                company:"",
                title:"",
                email:null,
                phones:"",
                tags:"",
                social:[],

            }
        },methods:{
            increaseSocialQuantity(){
               this.socialQuantity +=1
            },
            decreaseSocialQuantity(){
                if(this.socialQuantity > 1){
                     this.socialQuantity -=1
                }
            },
            onReset(){

                this.gender = null
                this.socialQuantity = 1  
                this.socialName = []
                this.socialLink = []
                this.name = ""
                this.company = ""
                this.title = null
                this.email = null
                this.phones = ""
                this.tags = ""
                this.social = []

            },addPerson(){

                console.log(this.socialName[1] + this.socialLink[1])
                console.log(this.socialName[2] + this.socialLink[2])
    
                for(var i = 1;i<this.socialName.length;i++){
                    this.social.push({social:this.socialName[i],link:this.socialLink[i]}) 
                }

                let titleArray = []
                if(this.title) titleArray = this.title.split(',')

                console.log(this.phones)
                let phonesArray = []
                if(this.phones) phonesArray = this.phones.split(',')

                let tagsArray = []
                if(this.tags) tagsArray = this.tags.split(',')


                let data = {
                    name: this.name,
                    company: this.company,
                    title: titleArray,
                    gender: this.gender,
                    email: this.email,
                    phone: phonesArray,
                    tags: tagsArray,
                    social:this.social
                }

                if(this.name.length > 0 && this.company.length > 0){
                    //lets post
                    this.$parent.openLoading()
                    editPerson(data,this.$route.params.id).then(() => {
                        this.$parent.closeLoading()
                        this.$parent.alertMessage = "The person " + this.name + " was edit successfully"
                        this.$parent.alertVariant = "success"
                        this.$parent.showAlert()
                    }).catch(() => {  
                        this.$parent.closeLoading()
                        this.$parent.alertMessage = "An error occurred while updating person"
                        this.$parent.alertVariant = "danger"
                        this.$parent.showAlert() 
                    })
    
                }else{
                        this.$parent.alertMessage = "Please ensure that all required fields are filled out before submitting."
                        this.$parent.alertVariant = "danger"
                        this.$parent.showAlert()
                 }
    
            }
        },mounted(){
            getPerson(this.$route.params.id).then(data =>{

                 this.name = data.name;
                 this.company = data.company;
                 this.gender = data.gender;

                 if(data.title){
                    if(data.title.length > 0){
                    data.title.forEach(element =>{
                            this.title += "," +element
                        })
                    this.title = this.title.substring(1)
                    }
                }

                 this.email = data.email;

                if(data.phone){
                    if(data.phone.length > 0){
                    data.phone.forEach(element =>{
                            this.phones += "," +element
                        })
                    this.phones = this.phones.substring(1)
                    }
                }

                if(data.tags){
                    if(data.tags.length > 0){
                    data.tags.forEach(element =>{
                            this.tags += "," +element
                        })
                    this.tags = this.tags.substring(1)
                    }
                }

                for(var i = 0;i<data.social.length;i++){
                    this.socialQuantity += 1
                    this.socialName[i + 1] = data.social[i].social
                    this.socialLink[i + 1] = data.social[i].link
                }

            }).catch((err) =>{
                this.$parent.alertMessage = "An error occurred while getting person informations " + err
                this.$parent.alertVariant = "danger"
                this.$parent.showAlert()
                this.$router.push({ name: 'People' });
            })
        }
        
    }
    </script>