<template>
    <div>
    
        People <br >

       <b-form-file
          v-model="file1"
          :state="Boolean(file1)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <div class="mt-3">Selected file: {{ file1 ? file1.name : '' }}</div>
    
        <b-button class="mt-3" variant="outline-primary" block @click="handleFile()">TEST</b-button>
    </div>
    </template>
     <script>
     import * as XLSX from 'xlsx/xlsx.mjs';
     import {createPerson} from '@/controller/repository.js'

     export default {
        data() {
    
          return {
                    file1: null,
            }
            
        },methods:{
          handleFile() {
        // const fileInput = document.getElementById('fileInput');
        // const file = fileInput.files[0];
        // console.log("trying to decode")
        // console.log(this.file1)
    
      const reader = new FileReader();
      reader.onload = function(e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet);
        // Do something with the rows
        // console.log(rows);
        // console.log(rows[0]["Phone(s) (. instead of +)"]);
        
      for(let i = 0;i<rows.length;i++){

        let titleArray = []
        if(rows[i]["Title(s)"]) titleArray = rows[i]["Title(s)"].split(',')
        // console.log(titleArray)

        let phonesArray = []
        if(rows[i]["Phone(s) (. instead of +)"]){
          phonesArray = rows[i]["Phone(s) (. instead of +)"].split(',')
        }

        phonesArray.forEach(function (phone, index) {
          phonesArray[index] = phone.replace(/\./g, "+");
        });

        let tagsArray = []
        if(rows[i]["Tag(s)"]) tagsArray = rows[i]["Tag(s)"].split(',')
        // console.log(tagsArray)

        let social = []

        if(rows[i]["Facebook link (without https://)"]){
          social.push({social:"Facebook",link:rows[i]["Facebook link (without https://)"]})
        }

        if(rows[i]["Twitter link (without https://)"]){
          social.push({social:"Twitter",link:rows[i]["Twitter link (without https://)"]})
        }

        if(rows[i]["Linkedin link (without https://)"]){
          social.push({social:"Linkedin",link:rows[i]["Linkedin link (without https://)"]})
        }

        if(rows[i]["Instagram link (without https://)"]){
          social.push({social:"Instagram",link:rows[i]["Instagram link (without https://)"]})
        }


          let data = {
              name: rows[i]["Person name"],
              company: rows[i]["Company name"],
              title: titleArray,
              gender: rows[i]["Gender"],
              email: rows[i]["Email"],
              phone: phonesArray,
              tags: tagsArray,
              social: social
            }

            // console.log(JSON.stringify(data))

         if(data.name.length > 0 && data.company.length > 0){
                    //lets post
                    createPerson(data).then(() => {
                      console.log("The person " + data.name + " was created successfully")
                    }).catch(err => {  
                      console.log("An error occurred while adding person " + data.name + err) 
                    })
    
                }else{
                  this.$parent.alertMessage = "Please ensure that all required fields are filled out before submitting."
                  this.$parent.alertVariant = "danger"
                  this.$parent.showAlert()
          }  

        }
    
      };
      reader.readAsArrayBuffer(this.file1);
    
      }
        }
    }

     </script>
