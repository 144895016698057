import Vue from 'vue'
import App from './App.vue'
import router from './router'

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

// import CrispChat from '@dansmaculotte/vue-crisp-chat'

// Vue.use(CrispChat, {
//   websiteId: '6c4af16d-3d09-4000-a8b4-a93cf98fb10c',
//   disabled: false,
//   hideOnLoad: false
// })
// // Vue.$crisp.do('chat:open')


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
