<template>
    <div>


<!-- <b-modal v-if="showResetPasswordDialog == true" title="Password reset" ok-disabled:t>
  <p class="my-4">Hello from modal!</p>
</b-modal>

<b-modal v-model="showResetPasswordDialog" title="Password reset" ok-only>

      <b-alert  :show="dismissCountDownModal" :variant="alertVariantModal" @dismissed="dismissCountDownModal=0" 
      @dismiss-count-down="countDownChangedModal">{{alertMessageModal}}</b-alert>

        <b-form-input
          id="name-input"
          v-model="resetPasswordEmail"
          required
          placeholder="Insert your email address"
        ></b-form-input>

          <center><b-button variant="primary" class="mt-1" @click="sendResetPassword()">Reset password</b-button></center>

  </b-modal> -->

<center> <h3 class="mt-2"> Login </h3></center>
<br>
  <b-container fluid>
<b-row class="justify-content-md-center mt-2" >
  <b-col sm="1"><h6>
    <label for="input-email" class="mt-2">Email</label>
    </h6>
  </b-col>
  <b-col sm="4">
  <input type="email" class="form-control" id="input-email" v-model = "email" placeholder="Enter email">
  </b-col>
</b-row>

<b-row class="justify-content-md-center mt-2" >
  <b-col sm="1"><h6>
    <label for="input-password" class="mt-2">Password</label>
    </h6>
  </b-col>
  <b-col sm="4">
  <input type="password" class="form-control" id="input-password" v-model = "password" placeholder="Enter password">
  </b-col>
</b-row>


<b-row class="justify-content-md-center mt-4" >
  <b-col sm="5">
  <button type="button" class="btn btn-primary btn-lg btn-block" @click="loginUser">Login</button>
  </b-col>
</b-row>

<!-- <div class="mt-2">
<center>Dont have an account ? 
    <router-link to="/register"> Sign up</router-link> | <b-link @click="showResetPasswordDialog = !showResetPasswordDialog"> Reset password</b-link>
</center>
</div> -->

<!--
<b-modal id="modal-1"  v-if="showResetPasswordDialog==true" title="Password reset">
  <p class="my-4">Hello from modal!</p>
</b-modal>
-->

</b-container>
</div>
</template>

<script>
import {authenticateUser} from '../controller/repository.js'
import {verifySession} from '../controller/repository.js'
// import {sendResetPasswordEmail} from '../controller/repository.js'

export default {

  beforeMount(){
    //If the user is logged in it should redirect to the homepage
    if(verifySession() == true || verifySession() == "Admin") this.$router.push({ name: 'MainPage' }) 
  //  if(verifySession() == "Partially") this.$router.push({ name: 'Email verification' }) 
  },

    data() {
      return {

          email: '',
          password:'',

          resetPasswordEmail:'',
          showResetPasswordDialog: false,
          dismissSecsModal: 4,
          dismissCountDownModal: 0,
          alertMessageModal: null,
          alertVariantModal: null,

          dismissSecs: 5,
          dismissCountDown: 0,
          alertMessage: null,
          alertVariant: null
        }
      }, methods:{

        // sendResetPassword(){
        //     if(this.resetPasswordEmail.length > 0){
        //         if(this.resetPasswordEmail.includes("@")){
        //             this.alertMessageModal = "Email sent successfully"
        //             this.alertVariantModal = "success"
        //             this.showAlertModal()
        //             console.log("ayt")

        //             sendResetPasswordEmail(this.resetPasswordEmail)

        //             //Make a repository to hold everything and post on server to send email
        //             //Make a new ResetPasswordpage with 2 fields
        //             //On mounted Verify and get the email account owner using token, if valid go to it, if invalid redirect to login
        //             //Verify if password match and if yes
        //             //Go to server and change the password using the email that we got on mounted and the new password
        //             //Do not forget to cryptograph the password
        //             //Redirect to login page
        //         }else{
        //             this.alertMessageModal = "Invalid email"
        //             this.alertVariantModal = "danger"
        //             this.showAlertModal()
        //         }
        //     }else{
        //       this.alertMessageModal = "Invalid email"
        //       this.alertVariantModal = "danger"
        //       this.showAlertModal()
        //     }
        // },


        loginUser(){

          let data = {
                    username: this.email,
                    password: this.password,
                    admin: true
                 }


                 if(data.username.length > 0 && data.password.length > 0){
                      if(this.email.includes("@")){

                   this.$parent.openLoading()
                     authenticateUser(data).then(data => {
                     // this.$emit('authenticateUser',data),
                      console.log(data)
                         this.$router.push({ name: 'MainPage' });

                      // //Send user to the page he deserves
                      // if(data.status == "Active"){
                         
                      //    this.$router.push({ name: 'MainPage' });
                      // }else if(data.status == "Blocked"){
                      //       //logout with message
                      // }else if(data.status == "Confirmation pending"){
                      //       //go to email confirmation page

                      //       this.$router.push({ name: 'Email verification' });

                            //and then test email confirmation *send email*
                            //make an email confirmation template
                      // }else if(data.status == "Payment pending"){
                      //       //go to email payment pending page
                      // }else if(data.status == "Admin"){
                      //    this.$router.push({ name: 'MainPage' });
                      // }


                      this.$parent.closeLoading()


                    }).catch((err) => {  
                        this.$parent.closeLoading()

/*
                      if(err == "Request failed with status code 500"){
                        this.alertMessage = "Email and password don't match" + err.message
//                       }
// */
//                       console.log("maneiro")
//                       console.log(err)
//                       console.log(err.message)
//                       console.log(err.response)
                   //   console.log(err.response.message)

                      this.$parent.alertMessage = err.response.data
                      this.$parent.alertVariant = "danger",
                      this.$parent.showAlert() 
                })

                 }else{
                //   Not all the field are filled correctly
                this.$parent.alertMessage = "Please fill all the fields"
                this.$parent.alertVariant = "danger"
                this.$parent.showAlert()  
                 }
                 }else{
                this.$parent.alertMessage = "Invalid email address"
                this.$parent.alertVariant = "danger"
                this.$parent.showAlert()  
                 }
         }

      ,countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
          
          showAlert() {
            this.dismissCountDown = this.dismissSecs
      },

        countDownChangedModal(dismissCountDownModal) {
        this.dismissCountDownModal = dismissCountDownModal
      },
          
          showAlertModal() {
            this.dismissCountDownModal = this.dismissSecsModal
      },
  
}
}
</script>