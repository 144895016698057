<template>
    <div>
    
        <h5 class="mt-4"><center>Add fundings </center></h5>
          <br />
    
      <div class= "ml-3" >
    
        <b-form >
    
    <b-container fluid>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-fundingdate">Funding date:</label>
        </b-col>
        <b-col sm="6">
            <b-form-datepicker id="input-fundingdate" size="sm"  v-model="fundingDate" placeholder="Enter funding date" class="mb-2"></b-form-datepicker>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-amount">Amount:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-amount" v-model= "amount" size="sm" placeholder="Enter funding amount (do not add commas or points)"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-currency">Currency:</label>
        </b-col>
        <b-col sm="6">
            <b-form-select id="currency" v-model="currency" :options="currencyOptions" size="sm"></b-form-select>
        </b-col>
      </b-row>
    

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-type">Type:</label>
        </b-col>
        <b-col sm="6">
            <b-form-select id="type" v-model="type" :options="typeOptions" size="sm"></b-form-select>
        </b-col>
      </b-row>
    

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-investors">Investors:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-investors" v-model= "investors" size="sm" placeholder="Enter investors separated by commas (Please consult the available investors before adding)"></b-form-input>
        </b-col>
      </b-row>
    
    
    </b-container>
    
         <center class="mt-2 mb-2">
            <router-link to="/Companies">  <b-button variant="success" >Back</b-button> </router-link>
          <b-button variant="primary"  class="ml-2" @click="addFunding()">Submit</b-button>
          <b-button type="reset" class="ml-2" variant="danger" @click="onReset">Reset</b-button>  
        </center>
    
    
        </b-form>
    
      </div>
      </div>
    </template>
    
    <script>
    import {addFundings} from '../../controller/repository.js'
    
    
    export default {
    
        data(){
            return{

            typeOptions: [
            { value: null, text: 'Please select an option' },
            { value: "Debt", text: 'Debt' },
            { value: "Grant", text: 'Grant' },
            { value: "Other", text: 'Other' },
            { value: 'Secondary Market', text: 'Secondary Market' },
            { value: 'Seed', text: 'Seed' },
            { value: 'Series A', text: 'Series A' },
            { value: 'Series B', text: 'Series B' },
            { value: 'Series C', text: 'Series C' },
            { value: 'Series D', text: 'Series D' },
            { value: 'Series E', text: 'Series E' },
            { value: 'Unknown', text: 'Unknown' },

            ],
            type: null,

            currencyOptions: [
            { value: null, text: 'Please select an option' },
            { value: "USD", text: 'USD' },
            { value: "EUR", text: 'EUR' },
            ],
            currency: null,

            fundingDate: null,
            amount:null,
            industries:"",
            investors:null,

            }
        },methods:{
            onReset(){
              this.type = null
              this.fundingDate = null
              this.amount = null
              this.industries = ""
              this.investors = null
              this.currency = null

            },addFunding(){

                let thisDate = new Date(this.fundingDate)
                
                let investorsArray = []
                if(this.investors) investorsArray = this.investors.split(',')

                let data = {
                    date: thisDate,
                    amount: this.amount,
                    type: this.type,
                    investors: investorsArray,
                    currency: this.currency,
                }
                console.log("data 2 " + JSON.stringify(data))
  
                if(this.amount.length > 0){
                    //lets post
                    this.$parent.openLoading()
                    addFundings(data,this.$route.params.id).then(() => {
                        this.$parent.closeLoading()
                        this.$parent.alertMessage = "The fundings were added successfully"
                        this.$parent.alertVariant = "success"
                        this.$parent.showAlert()
                        this.onReset()
                    }).catch(() => {  
                        this.$parent.closeLoading()
                        this.$parent.alertMessage = "An error occurred while adding fundings"
                        this.$parent.alertVariant = "danger"
                        this.$parent.showAlert() 
                    })
    
                }else{
                        this.$parent.alertMessage = "Please ensure that all required fields are filled out before submitting."
                        this.$parent.alertVariant = "danger"
                        this.$parent.showAlert()
                 }
    
            }
        }
        
    }
    </script>