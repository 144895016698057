<template>
    <div class= "ml-3 mt-3" >
  
        <h5><center>Fundings</center></h5>
     
<br />
<br />

<b-row class="justify-content-md-center mt-3 mr-4" >
   
    <b-col sm="2">
        <router-link to="/Companies">  <b-button variant="success" block>Back</b-button> </router-link>
        </b-col>
    <b-col sm="10">
    </b-col>
  </b-row>

  <div v-if="fundings.length == 0" >
    <b class="mt-4"> There is nothing to show </b>
    <br />
    <br />
    
    </div>

    <div class="mt-2 mr-3 mb-2" v-for = "funding in fundings.slice(10*(currentPage-1),10*(currentPage))" v-bind:key= 'funding._id' :per-page="perPage"
        :current-page="currentPage" id="my-fundings">
  
    <b-card :title= "funding.date"  :sub-title = "'Amount : ' + funding.amount">
      <b-card-text>
       Date: {{funding.date | moment("MMMM Do YYYY")}}  
       <br>
        ID: {{ funding._id }}
        <br>
        Type: {{ funding.type }}
        <br>
        Investors: {{ funding.investors }}
        <br>
          <b-link @click= "$bvModal.show('Alert-modal');updateFundingID(funding._id)">Delete</b-link>
      </b-card-text>
    </b-card>
    
  </div>
  
  <b-modal id="Alert-modal" hide-footer>
        <div class="d-block text-center">
          <h3>Are you sure you want to delete the funding information ?</h3>
        </div>
        <b-button class="mt-3" variant="outline-primary" block @click="$bvModal.hide('Alert-modal')">Cancel</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click= "delFunding(); $bvModal.hide('Alert-modal');">Delete </b-button>
      </b-modal>
  
    <b-pagination
        v-model="currentPage"
        :total-rows="this.fundings.length"
        :per-page="10"
      ></b-pagination>
  
  </div>
  </template>
  
  <script>
  import { getFundings } from '../../controller/repository';
  import { deleteFunding } from '../../controller/repository';
  
  import Vue from 'vue'
  Vue.use(require('vue-moment'));
  
  export default {
  
  
  data(){
  return{
    fundings : [],
    fundingID: null,
    perPage: 3,
    currentPage: 1
    }
  
  },methods:{

    updateFundingID(id){
        this.fundingID = id;
    },
  
  
    //Delete the funding based on the id that was passed through the user click
    delFunding(){
        deleteFunding(this.$route.params.id,this.fundingID,).then(() => 
        // //Deleted successfully
        //   this.$emit('deleteFunding', this.fundingID),

            getFundings(this.$route.params.id).then(data => {
          //Go get companies to print as a list
        //   this.getCompanies = data.companies
          this.fundings = data

          this.$parent.alertMessage = "The funding was deleted successfully"
          this.$parent.alertVariant = "success"
          this.$parent.showAlert()       
  
            })
        .catch(() => {
          this.$parent.alertMessage = "An error occurred while getting fundings"
          this.$parent.alertVariant = "danger"
          this.$parent.showAlert()       
        //   return Promise.reject('An error occurred ' + err)
        })).catch(() => {
          this.$parent.alertMessage = "An error occurred while deleting funding"
          this.$parent.alertVariant = "danger"
          this.$parent.showAlert()       
        })
  
    }
  
  },
  
    mounted(){
      getFundings(this.$route.params.id).then(data => {
          //Go get companies to print as a list
        //   this.getCompanies = data.companies
      
        this.fundings = data

        // //Lets put the companies in order
        //   var companiesInOrder = []
        //   for(let i = 0;i<this.getCompanies.length;i++){
        //     companiesInOrder.push(data.companies[i].name)
        //   }
    
        //   //Sort including uppercases and lowercases
        //   companiesInOrder.sort(function(s1, s2){
        //       var l=s1.toLowerCase(), m=s2.toLowerCase();
        //       return l===m?0:l>m?1:-1;
        //  });
      
        //   for(let j = 0;j<companiesInOrder.length;j++){
        //      for(let k = 0;k<this.getCompanies.length;k++){
        //         if(this.getCompanies[k].name == companiesInOrder[j]){
        //           //They are equals, lets add to the new spot
        //             this.companies.push(this.getCompanies[k])
        //         }
        //      }
        //   }
  
        })
        .catch((err) => {
          return Promise.reject('An error ocured ' + err)
        })
    }, 
  }
  </script>