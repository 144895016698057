<template>

    <div>
    </div>

</template>

<script>

export default {
    data(){
        return{

        }
    },mounted(){
       this.$parent.alertVariant = "warning"
       this.$parent.alertMessage = "Please login to access this page"
       this.$parent.showAlert()
       this.$router.push({name: 'Login'}).catch(()=>{});
    }
}
</script>