import axios from 'axios';
// const BASE_URL = 'http://localhost:3000';
// const BASE_URL = 'https://yutleads.com';
const BASE_URL = 'https://api.yutleads.com';

import Storage from 'localStorage'

//Configurations
var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': "Origin, X-Requested-With, Access-Control-Allow-Headers, Content-Type, Authorization",
        'Access-Control-Allow-Origin': "*",
        'Allow-Control-Allow-Methods': "GET, POST, PUT"
}

if(JSON.parse(Storage.getItem('userData'))){
        if(JSON.parse(Storage.getItem('userData')).token){
                headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(Storage.getItem('userData')).token,
                        'Accept': 'application/json',
                        'Access-Control-Allow-Headers': "Origin, X-Requested-With, Access-Control-Allow-Headers, Content-Type, Authorization",
                        'Access-Control-Allow-Origin': "*",
                        'Allow-Control-Allow-Methods': "GET, POST, PUT"
                }
        }
}

function updateHeader(){
if(JSON.parse(Storage.getItem('userData'))){
headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(Storage.getItem('userData')).token,
        'Accept': 'application/json',
        'Access-Control-Allow-Headers': "Origin, X-Requested-With, Access-Control-Allow-Headers, Content-Type, Authorization",
        'Access-Control-Allow-Origin': "*",
        'Allow-Control-Allow-Methods': "GET, POST, PUT"
}
}
}
//Configurations end


 
//User

export async function getUserToken(){
        return JSON.parse(Storage.getItem('userData')).token
}
 

export function createUser(data) {
        return axios.post(`${BASE_URL}/api/user/create`, {data})
                .then(response => {
                        //Login 
                        Storage.setItem('userData', JSON.stringify(response.data));
                        
                        
                       return response.data

                })
                .catch(err => Promise.reject(err),console.log("logged!"));
}

export function authenticateUser(data) {
        updateHeader()
        return axios.post(`${BASE_URL}/api/user/authenticate`, {data})
                .then(response => {
                //Save token and user data on localstorage
                console.log(response.data.status)
                // console.log(response)
                Storage.setItem('userData', JSON.stringify(response.data));
                // console.log("LOCATION ")
                // window.location.reload()
                // console.log("LOCATION 2")
                
                        return response.data
                })   .catch(err => Promise.reject(err));

        }

export function getUser(id){
        return axios.get(`${BASE_URL}/api/user/${id}`)
            .then(response => response.data)
            .catch(err => Promise.reject(err.message));
}

export function getUserDetails(){
        updateHeader()  

        // let specialHeader = headers
        // specialHeader.data = JSON.parse(Storage.getItem('userData')).token
        // let data = {token:JSON.parse(Storage.getItem('userData')).token}

        // headers.data = JSON.parse(Storage.getItem('userData')).token

        let token = JSON.parse(Storage.getItem('userData')).token
         return axios.get(`${BASE_URL}/api/user/getdetails/${token}` ,{headers})
         .then(response => {
                 return response.data
         }).catch(err => Promise.reject(err));
}

export function getUserBalance(){
        updateHeader()  

        
        let token = JSON.parse(Storage.getItem('userData')).token
         return axios.get(`${BASE_URL}/api/user/getbalance/${token}` ,{headers})
         .then(response => {
                 return response.data
         }).catch(err => Promise.reject(err));
}

export function verifySession(){

        if(Storage.getItem('userData')){
                // console.log("ok " + JSON.parse(Storage.getItem('userData')).status)
                
        if(JSON.parse(Storage.getItem('userData')).status != null && JSON.parse(Storage.getItem('userData')).status != "blocked"){
                //Login, good session
                // console.log("good session")
                return true
        }else{
                return false
        }
        
        // else{

        //         if(JSON.parse(Storage.getItem('userData')).status == "Confirmation pending"){
        //                 return "Partially"
        //         }
        //         //Logout, bad session
        //         return false
        //  }

        }else{
                return false
        }
}   


export async function logoutUser(){

        //  //Invalidate token
        //  invalidateToken(Storage.getItem('userData').token)
        //  .then(() => {

        //            //Delete storage
        //         localStorage.removeItem('userData');

        //          //Redirect to main page
        //          window.location = `/`
        //          return null
        //         // return Promise.resolve()
        //  })

        var token = JSON.parse(Storage.getItem('userData')).token
        return axios.put(`${BASE_URL}/api/token/invalidate`, {token},)
        .then(response => {

                //Delete storage
                localStorage.removeItem('userData');

                 //Redirect to login page
                 window.location = `/login`
                return response.data
        }).catch(err => {
                //Token wasn't well deleted, but let's remove from the localstorage and redirect to the home page
                
                //Delete storage
                localStorage.removeItem('userData');

                 //Redirect to login page
                 window.location = `/login`
                Promise.reject(err)
        });    

        
}

export function getUserData(){
        if(Storage.getItem('userData')){
                return JSON.parse(Storage.getItem('userData'))
        }
}

export function emailVerification(data){
        updateHeader()
        return axios.post(`${BASE_URL}/api/email/verification`, {data},{headers})
                .then(response => {
                        return response.data
                })   .catch(err => Promise.reject(err));
}

export function invalidateConfirmationCode(data){
        updateHeader()
        return axios.put(`${BASE_URL}/api/email/verification/invalidate/`, {data},{headers})
                .then(response => {
                        return response.data
                })   .catch(err => {console.log(err),Promise.reject(err)});
}


export function verifyEmail(token,email){
        updateHeader()

        const sha1 = require("sha1")

        //cryptograph the email
        // const Crypto = require('node-crypt');
        // console.log("mimae2")
        // const crypto = new Crypto({
        //         key: 'b95d8cb128734ff8821ea634dc34334535afe438524a782152d11a5248e71b01',
        //         hmacKey: 'dcf8cd2a90b1856c74a9f914abbb5f467c38252b611b138d8eedbe2abb4434fc'
        // });

        // console.log("token e ist" + token)
        if(JSON.parse(Storage.getItem('userData')).email){
                //If he is going to verify using code take the email from localstorage
                console.log(JSON.parse(Storage.getItem('userData')).email)
                email = sha1(JSON.parse(Storage.getItem('userData')).email)
        }else{
                email = sha1(email)
        }

            return axios.put(`${BASE_URL}/api/email/verification/${email}/${token}`,{headers})
                    .then(response => {

                  console.log(response.data)
                //Save data on localstorage
                Storage.setItem('userData', JSON.stringify(response.data));

                  return response.data

                })
                    .catch(err => Promise.reject(err));
        }

    export function saveUserData(token,email,status){
            var data = {
                "token": token,"email":email,"status":status
            }
            console.log("Ok" + JSON.stringify(data))
           Storage.setItem('userData', JSON.stringify(data));
        updateHeader()
        return
            
}

export function sendResetEmail(email){
        return axios.post(`${BASE_URL}/api/email/recover`, {email},)
        .then(response => {
                return response.data
        }).catch(err => Promise.reject(err));


}

export function verifyTokenStatus (token){
        //verify on db
        return axios.post(`${BASE_URL}/api/token/verify`, {token},)
        .then(response => {
                return response.data
        }).catch(err => Promise.reject(err));
}


export function invalidateToken(token){
        return axios.put(`${BASE_URL}/api/token/invalidate`, {token},)
        .then(response => {
                return response.data
        }).catch(err => Promise.reject(err));

}

export function updateEmail(email){
        updateHeader()
        var data = {
                 token:JSON.parse(Storage.getItem('userData')).token,
                 email:email
         }
         return axios.put(`${BASE_URL}/api/user/updateEmail`, {data},{headers})
         .then(response => {
                 return response.data
         }).catch(err => Promise.reject(err));
 
 }

 export function updatePersonalDetails(data){
        updateHeader()

         data.token = JSON.parse(Storage.getItem('userData')).token
                console.log("here")
         return axios.put(`${BASE_URL}/api/user/updatePersonalDetails`, {data},{headers})
         .then(response => {
                 return response.data
         }).catch(err => Promise.reject(err));
 
 }


 export function updatePassword(oldPassword,password){
        updateHeader()

        var data = {
                oldPassword:oldPassword,
                 password:password,
                 token:JSON.parse(Storage.getItem('userData')).token
         }
         return axios.put(`${BASE_URL}/api/user/updatePassword`, {data},{headers})
         .then(response => {
                 return response.data
         }).catch(err => Promise.reject(err));
 
 }

 //Company

 export function getCompanies() {
        return axios.get(`${BASE_URL}/api/companies/list`,{headers})
                .then(response => response.data).catch(err => Promise.reject(err));
}

export function getCompany(id) {
    return axios.get(`${BASE_URL}/api/company/${id}`,{headers})
            .then(response => response.data)
            .catch(err => Promise.reject(err));
}
 
export function deleteCompany(id){
        updateHeader()
        let data = {id:id}
        return axios.post(`${BASE_URL}/api/company/delete/`, {data}, {headers})
                .then(response => response.data)
                .catch(err => Promise.reject(err));
}


export function createCompany(data) {
        updateHeader()
        return axios.post(`${BASE_URL}/api/company/create`, {data},{headers}
        ).then(response => {
                        return response.data
                })
                .catch(err => Promise.reject(err));
}

 
export function editCompany(data, id) {
        return axios.post(`${BASE_URL}/api/company/edit/${id}`, {data},{headers})
                .then(response => {
                        return response.data
                })
                .catch(err => Promise.reject(err));
}


export function addFundings(data, id) {
        return axios.put(`${BASE_URL}/api/company/addfundings/${id}`, {data},{headers})
                .then(response => {
                        return response.data
                })
                .catch(err => Promise.reject(err));
}

export function addFundingsByName(data) {
        return axios.put(`${BASE_URL}/api/company/addfundingsbyname`, {data},{headers})
                .then(response => {
                        return response.data
                })
                .catch(err => Promise.reject(err));
}



export function getFundings(id) {
        updateHeader()
        return axios.get(`${BASE_URL}/api/companies/getfundings/${id}`,{headers})
                .then(response => response.data).catch(err => Promise.reject(err));
}

export function deleteFunding(id,fundingID){
        updateHeader()
        let data = {fundingID: fundingID,id:id}
            return axios.post(`${BASE_URL}/api/companies/deletefunding/` , {data},{headers})
                    .then(response => response.data)
                    .catch(err => Promise.reject(err));
    }
    

//People

export function getPeople() {
        return axios.get(`${BASE_URL}/api/people/list`,{headers})
                .then(response => response.data).catch(err => Promise.reject(err));
}

export function createPerson(data) {
        updateHeader()
        return axios.post(`${BASE_URL}/api/person/create`, {data},{headers}
        ).then(response => {
                        return response.data
                })
                .catch(err => Promise.reject(err));
}

export function deletePerson(id){
        updateHeader()
        let data = {id:id}
        return axios.post(`${BASE_URL}/api/person/delete/`, {data}, {headers})
                .then(response => response.data)
                .catch(err => Promise.reject(err));
}

export function editPerson(data, id) {
        return axios.post(`${BASE_URL}/api/person/edit/${id}`, {data},{headers})
                .then(response => {
                        return response.data
                })
                .catch(err => Promise.reject(err));
}

export function getPerson(id) {
        return axios.get(`${BASE_URL}/api/person/${id}`,{headers})
                .then(response => response.data)
                .catch(err => Promise.reject(err));
    }