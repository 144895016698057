<template>
    <div class= "ml-3 mt-3" >
  
        <h5><center>People </center></h5>
     
<br />
<br />

<b-row class="justify-content-md-center mt-3 mr-4" >
   
    <b-col sm="2">
        <router-link to="/">  <b-button variant="success" block>Back</b-button> </router-link>
        </b-col>
        <b-col sm="2">
            <router-link to="/people/add/">  <b-button variant="primary" block>Add person</b-button></router-link> 
    </b-col>
    <b-col sm="8">
    </b-col>
  </b-row>

  <div v-if="people.length == 0" >
    <b class="mt-4"> There is nothing to show </b>

    <br />
    <br />

    </div>

    <div class="mt-2 mr-3 mb-2" v-for = "person in people.slice(10*(currentPage-1),10*(currentPage))" v-bind:key= 'person.name' :per-page="perPage"
        :current-page="currentPage" id="my-people">
  
    <b-card :title= "person.name"  :sub-title = "'Title : ' + person.title">
      <b-card-text>
        This person was added on : {{person.createdAt | moment("MMMM Do YYYY")}}
        <br>
        ID: {{ person._id }}
        <br>
  
        <router-link :to="{ name: 'Edit People', params: { id: person._id }}">
          <b-link href="href">Edit</b-link> 
         </router-link> &nbsp;&nbsp;&nbsp;
  
          <b-link @click= "$bvModal.show('Alert-modal'); showModal(person._id,person.name);">Delete</b-link>
          &nbsp;&nbsp;&nbsp;
      </b-card-text>
    </b-card>
    
  </div>
  
  <b-modal id="Alert-modal" hide-footer>
        <div class="d-block text-center">
          <h3>Are you sure you want to delete the person <b>{{personName}} ?</b></h3>
        </div>
        <b-button class="mt-3" variant="outline-primary" block @click="$bvModal.hide('Alert-modal')">Cancel</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click= "delPerson(); $bvModal.hide('Alert-modal');">Delete </b-button>
      </b-modal>
  
    <b-pagination
        v-model="currentPage"
        :total-rows="this.people.length"
        :per-page="20"
      ></b-pagination>
  
  </div>
  </template>
  
  <script>
  import { getPeople} from '../../controller/repository';
  import { deletePerson } from '../../controller/repository';
  
  import Vue from 'vue'
  Vue.use(require('vue-moment'));
  
  export default {
  
  
  data(){
  return{
    people : [],
    personID: null,
    personName: null,
  
    perPage: 3,
    currentPage: 1
    }
  
  },methods:{
  
    //Show modal to confirm if the user really wants to delete person
    showModal(id,name){
      this.personName = name
      this.personID = id
    },
  
  
    //Delete the person based on the id that was passed through the user click
    delPerson(){
      console.log(this.personID)
        deletePerson(this.personID).then(() => 

          //Update the people on page and then show the alert
          getPeople().then(data => {
          this.people = data.people
          this.$parent.alertMessage = "The person " + this.personName + " was deleted successfully"
          this.$parent.alertVariant = "success"
          this.$parent.showAlert()       
  })
        .catch((err) => {
          this.$parent.alertMessage = "An error occurred while deleting person"
          this.$parent.alertVariant = "danger"
          this.$parent.showAlert()       
          return Promise.reject('An error occurred ' + err)
        }),
        )
            .catch(err => alert(err))
  
    }
  
  },
  
    mounted(){
      getPeople().then(data => {
          //Go get people to print as a list
          this.people = data.people
        })
        .catch((err) => {
          return Promise.reject('An error ocured ' + err)
        })
    }, 
  }
  </script>