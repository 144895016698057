<template>
<div>

  People <br >


   <b-form-file
      v-model="file1"
      :state="Boolean(file1)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    ></b-form-file>
    <div class="mt-3">Selected file: {{ file1 ? file1.name : '' }}</div>

    <b-button class="mt-3" variant="outline-primary" block @click="handleFile()">ADD</b-button>
</div>
</template>

<script>
import * as XLSX from 'xlsx/xlsx.mjs';
import {createCompany} from '@/controller/repository.js';


export default {
    data() {

      return {
                file1: null,
        }
        
    },methods:{
      handleFile() {
    // const fileInput = document.getElementById('fileInput');
    // const file = fileInput.files[0];
    // console.log("trying to decode")
    // console.log(this.file1)

  const reader = new FileReader();
  reader.onload = function(e) {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const rows = XLSX.utils.sheet_to_json(sheet);
    // Do something with the rows
    // console.log(rows);
    // console.log(rows[0]["Phone(s) (. instead of +)"]);
    
    for(let i = 0;i<rows.length;i++){

      let dateString = rows[i]["Founding date (d-m-y)"]
      const thisDate = new Date(dateString.split("-").reverse().join("-"));
      // console.log("date " + thisDate)

      let revenueString = rows[i]["Revenue (year:revenue;)"]
      let revenues = []
      let arr = revenueString.split(";");
      arr.forEach(element => {
        if(element.includes(":")){
        let yearValue = element.split(":");
        let obj = {
          year: yearValue[0],
          amount: yearValue[1]
        }
        revenues.push(obj);
        }
      });
      // console.log(revenues);
     
      let industriesArray = []
      if(rows[i]["Industry(ies)"]) industriesArray = rows[i]["Industry(ies)"].split(',')
      // console.log(industriesArray)
      
      let emailsArray = []
      if(rows[i]["Email(s)"]) emailsArray = rows[i]["Email(s)"].split(',')
      // console.log(emailsArray)

      let phonesArray = []
      if(rows[i]["Phone(s) (. instead of +)"]){
        phonesArray = rows[i]["Phone(s) (. instead of +)"].split(',')
      }

      phonesArray.forEach(function (phone, index) {
        phonesArray[index] = phone.replace(/\./g, "+");
      });

      // console.log(phonesArray)

        
      let tagsArray = []
      if(rows[i]["Tag(s)"]) tagsArray = rows[i]["Tag(s)"].split(',')
      // console.log(tagsArray)

      let technologiesArray = []
      if(rows[i]["Technology(ies)"]) technologiesArray = rows[i]["Technology(ies)"].split(',')

      let data = {
         name: rows[i]["Company name"],
         description: rows[i]["Description"],
         foundingDate: thisDate,
         website: rows[i]["Website"],
         type: rows[i]["Type"],
         industries: industriesArray,
         email: emailsArray,
         phone: phonesArray,
         tags: tagsArray,
         size: rows[i]["Size"],
         continent: rows[i]["Continent"],
         country: rows[i]["Country"],
         stateOrProvince: rows[i]["State or Province"],
         city: rows[i]["City"],
         revenue: revenues,
         technologies: technologiesArray
      }

      // console.log("data 1 " + data)
      // console.log("data 2 " + JSON.stringify(data))
  
      if(data.name.length > 0 && data.industries.length > 0 && data.continent != null && data.country.length > 0){
        
        // this.$parent.openLoading()
        createCompany(data).then(() => {
          // this.$parent.closeLoading()
          console.log("The company " + data.name + " was created successfully")
          // this.$parent.alertMessage = "The company " + this.name + " was created successfully"
          // this.$parent.alertVariant = "success"
          // this.$parent.showAlert()
        }).catch(() => {  
          // this.$parent.closeLoading()
          console.log("An error occurred while adding company " + data.name)
          // this.$parent.alertMessage = "An error occurred while adding company"
          // this.$parent.alertVariant = "danger"
          // this.$parent.showAlert() 
        })

      }else{
        this.$parent.alertMessage = "Please ensure that all required fields are filled out before submitting."
        this.$parent.alertVariant = "danger"
        this.$parent.showAlert()
      }

    }

  };
  reader.readAsArrayBuffer(this.file1);

  }
    }
}

</script>
