<template>
    <div>

        <div style="background:#E8ECEF">

<br />
<br />

<div style="color:#3E4246;font-size: 45px;margin-left:290px;"> 
 Welcome to the data administrator panel!
</div>

<div style="color:#3E4246;font-size: 15px;margin-left:290px;"> 
 Please keep in note that a lot of the processes here are manual and will require your undivided attention. <br />
 If you find any bug, do not hesitate to contact the website administrator.
</div>

<b-button variant="danger" href="#" style="margin-left:290px;" class="mt-2">Contact</b-button>

<br />
<br />
<br />
<br />

</div>

<b-container class="bv-example-row">
<b-row>
<b-col></b-col>

<b-col >
      <div style="color:#3E4246;font-size: 22px;" class="mt-3"> 
        <b> Companies </b>
    </div>

     <div style="color:#3E4246;font-size: 15px;"> 
        In this module you shall be able to register, view and delete companies. 
    </div>

    <router-link to="/Companies">   <b-button class="mt-2">Open companies</b-button> </router-link>


</b-col>

<b-col >
      <div style="color:#3E4246;font-size: 22px;" class="mt-3"> 
        <b> People </b>
    </div>

     <div style="color:#3E4246;font-size: 15px;"> 
        In this module you shall be able to register, view and delete people. 
    </div>

    <router-link to="/People">   <b-button class="mt-2">Open people</b-button> </router-link>


</b-col>

    <b-col >
      <div style="color:#3E4246;font-size: 22px;" class="mt-3"> 
        <b> Technologies </b>
    </div>

     <div style="color:#3E4246;font-size: 15px;"> 
        In this module you shall be able to register, view and delete technologies. 
    </div>

      <b-button class="mt-2" variant="danger">Open technologies</b-button>


</b-col>

 <b-col >
      <div style="color:#3E4246;font-size: 22px;" class="mt-3"> 
        <b> Roles </b>
    </div>

     <div style="color:#3E4246;font-size: 15px;"> 
        In this module you shall be able to register, view and delete employee roles. 
    </div>

      <b-button class="mt-2" variant="danger">Open roles</b-button>


</b-col>

<b-col></b-col>

</b-row>
</b-container>


</div>
</template>

<script>
export default {

}
</script>

<style>

</style>