<template>
    <div class= "ml-3 mt-3" >
  
        <h5><center>Companies </center></h5>
     
<br />
<br />

<b-row class="justify-content-md-center mt-3 mr-4" >
   
    <b-col sm="2">
        <router-link to="/">  <b-button variant="success" block>Back</b-button> </router-link>
        </b-col>
        <b-col sm="2">
            <router-link to="/companies/add/">  <b-button variant="primary" block>Add company</b-button></router-link> 
    </b-col>
    <b-col sm="8">
    </b-col>
  </b-row>

  <div v-if="companies.length == 0" >
    <b class="mt-4"> There is nothing to show </b>

    <br />
    <br />

    </div>

    <div class="mt-2 mr-3 mb-2" v-for = "company in companies.slice(10*(currentPage-1),10*(currentPage))" v-bind:key= 'company.name' :per-page="perPage"
        :current-page="currentPage" id="my-companies">
  
    <b-card :title= "company.name"  :sub-title = "'Description : ' + company.description">
      <b-card-text>
        This company was added on : {{company.createdAt | moment("MMMM Do YYYY")}}
        <br>
        ID: {{ company._id }}
        <br>
  
        <router-link :to="{ name: 'Edit Companies', params: { id: company._id }}">
          <b-link href="href">Edit</b-link> 
         </router-link> &nbsp;&nbsp;&nbsp;
  
          <b-link @click= "$bvModal.show('Alert-modal'); showModal(company._id,company.name);">Delete</b-link>
          &nbsp;&nbsp;&nbsp;
          <router-link :to="{ name: 'Add Fundings', params: { id: company._id }}"> Add fundings </router-link>
          &nbsp;&nbsp;&nbsp;
          <router-link :to="{ name: 'Show Fundings', params: { id: company._id }}">Show fundings </router-link>

      </b-card-text>
    </b-card>
    
  </div>
  
  <b-modal id="Alert-modal" hide-footer>
        <div class="d-block text-center">
          <h3>Are you sure you want to delete the company <b>{{companyName}} ?</b></h3>
        </div>
        <b-button class="mt-3" variant="outline-primary" block @click="$bvModal.hide('Alert-modal')">Cancel</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click= "delCompany(); $bvModal.hide('Alert-modal');">Delete </b-button>
      </b-modal>
  
    <b-pagination
        v-model="currentPage"
        :total-rows="this.companies.length"
        :per-page="10"
      ></b-pagination>
  
  </div>
  </template>
  
  <script>
  import { getCompanies } from '../../controller/repository';
  import { deleteCompany } from '../../controller/repository';
  
  import Vue from 'vue'
  Vue.use(require('vue-moment'));
  
  export default {
  
  
  data(){
  return{
    companies : [],
    companyID: null,
    companyName: null,
  
    perPage: 10,
    currentPage: 1
    }
  
  },methods:{
  
  
    //Show modal to confirm if the user really wants to delete company
    showModal(id,name){
      this.companyName = name
      this.companyID = id
    },
  
  
    //Delete the company based on the id that was passed through the user click
    delCompany(){
      console.log(this.companyID)
        deleteCompany(this.companyID).then(() => 
        //Deleted successfully
        //   this.$emit('deleteCompany', this.companyID),
  
          //Update the companies on page and then show the alert
          getCompanies().then(data => {
          //Go get companies to print as a list
        //   this.getCompanies = data.companies
        //   this.companies = this.getCompanies
          this.companies = data.companies
          this.$parent.alertMessage = "The company " + this.companyName + " was deleted successfully"
          this.$parent.alertVariant = "success"
          this.$parent.showAlert()       
  })
        .catch((err) => {
          this.$parent.alertMessage = "An error occurred while deleting company"
          this.$parent.alertVariant = "danger"
          this.$parent.showAlert()       
          return Promise.reject('An error occurred ' + err)
        }),
        )
            .catch(err => alert(err))
  
    }
  
  },
  
    mounted(){
      getCompanies().then(data => {
          //Go get companies to print as a list
          this.getCompanies = data.companies
  
        //Lets put the companies in order
          var companiesInOrder = []
          for(let i = 0;i<this.getCompanies.length;i++){
            companiesInOrder.push(data.companies[i].name)
          }
    
          //Sort including uppercases and lowercases
          companiesInOrder.sort(function(s1, s2){
              var l=s1.toLowerCase(), m=s2.toLowerCase();
              return l===m?0:l>m?1:-1;
         });
      
          for(let j = 0;j<companiesInOrder.length;j++){
             for(let k = 0;k<this.getCompanies.length;k++){
                if(this.getCompanies[k].name == companiesInOrder[j]){
                  //They are equals, lets add to the new spot
                    this.companies.push(this.getCompanies[k])
                }
             }
          }
  
        })
        .catch((err) => {
          return Promise.reject('An error ocured ' + err)
        })
    }, 
  }
  </script>