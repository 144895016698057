<template>
    <div>
    
        <h5 class="mt-4"><center>Add company </center></h5>
          <br />
    
      <div class= "ml-3" >
    
        <b-form >
    
    <b-container fluid>
    
     <b-row class="justify-content-md-center">
        <b-col sm="2">
          <label for="input-companyname">Company name:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-companyname" v-model= "name" size="sm" placeholder="Enter company name"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-descriptions">Description:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-descriptions" v-model= "description" size="sm" placeholder="Enter description (optional)"></b-form-input>
        </b-col>
      </b-row>
    

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-foundingdate">Founding date:</label>
        </b-col>
        <b-col sm="6">
            <b-form-datepicker id="input-foundingdate" size="sm"  v-model="foundingDate" placeholder="Enter founding date (optional)" class="mb-2"></b-form-datepicker>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-website">Website:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-website" v-model= "website" size="sm" placeholder="Enter website (add www.) (optional)"></b-form-input>
        </b-col>
      </b-row>
    

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-type">Type:</label>
        </b-col>
        <b-col sm="6">
            <b-form-select id="type" v-model="type" :options="typeOptions" size="sm"></b-form-select>
        </b-col>
      </b-row>
    

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-industries">Industries:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-industries" v-model= "industries" size="sm" placeholder="Enter industries separated by commas (Please consult the available industries before adding)"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-emails">Emails:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-emails" v-model= "emails" size="sm" placeholder="Enter emails separated by commas (optional)"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-phones">Phone numbers:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-phones" v-model= "phones" size="sm" placeholder="Enter phone numbers separated by commas (use + country code) (optional)"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-tags">Tags:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-tags" v-model= "tags" size="sm" placeholder="Enter tags separated by commas (optional)"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-size">Company size:</label>
        </b-col>
        <b-col sm="6">
            <b-form-select id="size" v-model="size" :options="sizeOptions" size="sm"></b-form-select>
        </b-col>
      </b-row>
    
      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-size">Continent:</label>
        </b-col>
        <b-col sm="6">
            <b-form-select id="continent" v-model="continent" :options="continentOptions" size="sm"></b-form-select>
        </b-col>
      </b-row>
    
      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-country">Country:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-country" v-model= "country" size="sm" placeholder="Enter country"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-stateorprovince">State or Province:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-stateorprovince" v-model= "stateorprovince" size="sm" placeholder="Enter state or province (optional)"></b-form-input>
        </b-col>
      </b-row>

      <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-city">City:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-city" v-model= "city" size="sm" placeholder="Enter city (optional)"></b-form-input>
        </b-col>
      </b-row>



    
    <div v-for="revenueField in revenueQuantity" v-bind:key = "'revenueField' + revenueField" >
         <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-revenueprinting">Revenue [{{revenueField}}]: </label>
        </b-col>
        <b-col sm="6">
    
      <b-input-group>
        <b-form-input placeholder="Year (optional)" size="sm" v-model="revenueYear[revenueField]"></b-form-input>
        <b-form-input placeholder="Amount (optional) (do not add commas or points)" size="sm" v-model="revenueAmount[revenueField]"></b-form-input>
    
        <div v-if="revenueField==revenueQuantity">
            <b-button size="sm" class="ml-2" @click="increaseRevenueQuantity()">
                    <b-icon icon="plus" ></b-icon>
            </b-button>
    
               <b-button size="sm" class="ml-1" @click="decreaseRevenueQuantity()">
                    <b-icon icon="dash" ></b-icon>
            </b-button>
        </div>
      </b-input-group>
    
          </b-col>
      </b-row>
    </div>



    <b-row class="justify-content-md-center mt-2">
        <b-col sm="2">
          <label for="input-technologies">Technologies:</label>
        </b-col>
        <b-col sm="6">
          <b-form-input id="input-technologies" v-model= "technologies" size="sm" placeholder="Enter technologies separated by commas (optional) (Please consult the available technologies before adding)"></b-form-input>
        </b-col>
      </b-row>
    
    
    </b-container>
    
         <center class="mt-2 mb-2">
            <router-link to="/Companies">  <b-button variant="success" >Back</b-button> </router-link>
          <b-button variant="primary"  class="ml-2" @click="addCompany()">Submit</b-button>
          <b-button type="reset" class="ml-2" variant="danger" @click="onReset">Reset</b-button>  
        </center>
    
    
        </b-form>
    
      </div>
      </div>
    </template>
    
    <script>
    import {createCompany} from '../../controller/repository.js'
    
    export default {
    
        data(){
            return{

            typeOptions: [
            { value: null, text: 'Please select an option (optional)' },
            { value: "Public", text: 'Public' },
            { value: 'Private', text: 'Private' },
            { value: 'Government', text: 'Government' },
            { value: 'Non-Profit', text: 'Non-Profit' },
            ],
            type: null,
            
            sizeOptions: [
            { value: null, text: 'Please select an option (optional)' },
            { value: "1-10", text: '1-10' },
            { value: '11-50', text: '11-50' },
            { value: '51-100', text: '51-100' },
            { value: '101-250', text: '101-250' },
            { value: '251-500', text: '501-1000' },
            { value: '1001-10000', text: '1001-10000' },
            { value: '10001+', text: '10001+' },

            ],
            size: null,

            continentOptions: [
            { value: "", text: 'Please select an option (optional)' },
            { value: "Africa", text: 'Africa' },
            { value: 'Asia', text: 'Asia' },
            { value: 'Europe', text: 'Europe' },
            { value: 'North America', text: 'North America' },
            { value: 'Oceania', text: 'Oceania' },
            { value: 'South America', text: 'South America' },
            ],
            continent: "",

            revenueQuantity: 1,    
            revenueAmount: [],
            revenueYear: [],

        
            name:"",
            description:"",
            foundingDate: null,
            website:null,
            // type:null,
            industries:"",
            emails:null,
            phones:null,
            tags:null,
            // size:null,
            // continent:null,
            country:"",
            stateorprovince:null,
            city:null,
            revenue:[],
            technologies:null,


    
            }
        },methods:{
            increaseRevenueQuantity(){
               this.revenueQuantity +=1
            },
            decreaseRevenueQuantity(){
                if(this.revenueQuantity > 1){
                     this.revenueQuantity -=1
                }
            },
            onReset(){
                this.revenueQuantity = 1,
                this.revenueAmount = [],
                this.revenueYear = [],
    
                this.name = "",
                this.description = "",
                this.foundingDate = null,
                this.website = null,
                this.type = null,
                this.industries = null,
                this.emails = null,
                this.phones = null,
                this.tags = null,
                this.size = null,
                this.continent = null,
                this.country = "",
                this.stateorprovince = null,
                this.city = null,
                this.revenue = [],
                this.technologies = null

            },addCompany(){

                console.log(this.revenueYear[1] + this.revenueAmount[1])
                console.log(this.revenueYear[2] + this.revenueAmount[2])
    
                for(var i = 1;i<this.revenueYear.length;i++){
                    this.revenue.push({year:this.revenueYear[i],amount:this.revenueAmount[i]}) 
                }

                // console.log("mbaaa " + JSON.stringify(this.revenue))
                // console.log("name " + this.name)
                // console.log("description " + this.description)
                // console.log("founding date " + this.foundingDate)
                let thisDate = new Date(this.foundingDate)
                // console.log("this date " + thisDate)

                // console.log("website " + this.website)
                // console.log("type " + this.type)
                // console.log("industries " + this.industries)
                // console.log("emails " + this.emails)
                // console.log("phones " + this.phones)
                // console.log("tags " + this.tags)
                // console.log("size " + this.size)
                // console.log("continent " + this.continent)
                // console.log("country " + this.country)
                // console.log("stateorprovince " + this.stateorprovince)
                // console.log("city " + this.city)
                // console.log("technologies " + this.technologies)

                let industriesArray = []
                if(this.industries) industriesArray = this.industries.split(',')

                let emailsArray = []
                if(this.emails) emailsArray = this.emails.split(',')

                let phonesArray = []
                if(this.phones) phonesArray = this.phones.split(',')

                let tagsArray = []
                if(this.tags) tagsArray = this.tags.split(',')

                let technologiesArray = []
                if(this.technologies) technologiesArray = this.technologies.split(',')

                let data = {
                    name: this.name,
                    description: this.description,
                    foundingDate: thisDate,
                    website: this.website,
                    type: this.type,
                    industries: industriesArray,
                    email: emailsArray,
                    phone: phonesArray,
                    tags: tagsArray,
                    size: this.size,
                    continent: this.continent,
                    country: this.country,
                    stateOrProvince: this.stateorprovince,
                    city: this.city,
                    revenue: this.revenue,
                    technologies: technologiesArray
                }

                console.log("data 1 " + data)
                console.log("data 2 " + JSON.stringify(data))
  
                if(this.name.length > 0 && this.industries.length > 0 && this.continent != null && this.country.length > 0){
                    //lets post
                    this.$parent.openLoading()
                    createCompany(data).then(() => {
                        this.$parent.closeLoading()
                        this.$parent.alertMessage = "The company " + this.name + " was created successfully"
                        this.$parent.alertVariant = "success"
                        this.$parent.showAlert()
                        this.onReset()
                    }).catch(() => {  
                        this.$parent.closeLoading()
                        this.$parent.alertMessage = "An error occurred while adding company"
                        this.$parent.alertVariant = "danger"
                        this.$parent.showAlert() 
                    })
    
                }else{
                        this.$parent.alertMessage = "Please ensure that all required fields are filled out before submitting."
                        this.$parent.alertVariant = "danger"
                        this.$parent.showAlert()
                 }
    
            }
        }
        
    }
    </script>