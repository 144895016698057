import Vue from 'vue'
import Router from 'vue-router'
import {verifySession} from '../controller/repository.js'

//Pages
import MainPage from '@/components/MainPage'
// import Register from '@/components/Register'
import Login from '@/components/Login'
import GlobalModal from '@/components/GlobalModal'


import Companies from '@/components/Companies/Home'
import AddCompanies from '@/components/Companies/Add'
import EditCompanies from '@/components/Companies/Edit'
import AddBulkCompanies from '@/components/Companies/AddBulk'

import AddFundings from '@/components/Companies/AddFundings'
import AddBulkFundings from '@/components/Companies/AddBulkFundings'
import ShowFundings from '@/components/Companies/ShowFundings'

import People from '@/components/People/Home'
import AddPeople from '@/components/People/Add'
import EditPeople from '@/components/People/Edit'
import AddBulkPeople from '@/components/People/AddBulkPeople'

Vue.use(Router)

//Icons
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const routes = [{
        path: '/',
        name: 'MainPage',
        component: MainPage
    },
    // {
    //     path: '/Register',
    //     name: 'Register',
    //     component: Register
    // },
    {
        path: '/Login',
        name: 'Login',
        component: Login
    }

    //Companies

    ,{
        path: '/Companies',
        name: 'Companies',
        component: Companies
    },{
        path: '/Companies/Add',
        name: 'Add Companies',
        component: AddCompanies
    },{
        path: '/Companies/AddBulkCompanies',
        name: 'Add Bulk Companies',
        component: AddBulkCompanies
    },{
        path: '/Companies/Edit/:id',
        name: 'Edit Companies',
        component: EditCompanies
    },{
        path: '/Companies/AddFundings/:id',
        name: 'Add Fundings',
        component: AddFundings
    },{
        path: '/Companies/AddBulkFundings',
        name: 'Add Bulk Fundings',
        component: AddBulkFundings
    },{
        path: '/Companies/ShowFundings/:id',
        name: 'Show Fundings',
        component: ShowFundings
    }
    
    //People
    
    ,{
        path: '/People',
        name: 'People',
        component: People
    },{
        path: '/People/Add',
        name: 'Add People',
        component: AddPeople
    },{
        path: '/People/AddBulkPeople',
        name: 'Add Bulk People',
        component: AddBulkPeople
    },{
        path: '/People/Edit/:id',
        name: 'Edit People',
        component: EditPeople
    },{
        path: '/GlobalModal',
        name: 'GlobalModal',
        component: GlobalModal
    }

]


const router = new Router({
    mode: 'history',
    routes,
       //Every time we change pages we will scroll up
       scrollBehavior () {
        return { x: 0, y: 0 }
      }
});

router.beforeEach((to, from,next) => {

    Vue.nextTick(() =>{
        document.title = "YutLeads! Unlock the full potential of your sales"
    })

    let unprotectedRoutes = ['GlobalModal','Login']

    if(unprotectedRoutes.includes(to.name)){
      //Its an unprotected route 
     next()
    }else{
    //Its a protected route
    if(verifySession()){
        next()
      }else{
        //Show login and register popup
        next({name:'GlobalModal'})
     }
    }
})

export default router;
